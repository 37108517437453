<template>
    <div class="container">
        <div class="navBox">
            <img class="goback" :src="Left" @click="toBack" />
            <span>{{ $t("PasswordVerification") }}</span>
            <div style="width:0.5rem"></div>
        </div>
        <div class="text">
            <span class="textTitle">{{ $t("VerifyAccountPassword") }}</span>
            <span class="textDescription">{{ $t("VerifyDes") }}</span>
            <div class="settingInput">
                <svg class="brownSvg" slot="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V9H19C20.1046 9 21 9.89543 21 11V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V11C3 9.89543 3.89543 9 5 9H6V7ZM16 7V9H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 14.7998C14 15.5625 13.5731 16.2255 12.9451 16.5628L13.5612 18.1029C13.7714 18.6284 13.3843 19.2 12.8184 19.2H11.1816C10.6157 19.2 10.2286 18.6284 10.4388 18.1029L11.0549 16.5628C10.4269 16.2255 10 15.5625 10 14.7998C10 13.6952 10.8954 12.7998 12 12.7998C13.1046 12.7998 14 13.6952 14 14.7998Z" fill="#039685"></path>
                </svg>
                <input type="password" v-model="cpf" :placeholder="$t('Password')" />
            </div>
            <div class="saveButton" @click="confirm">
                {{ $t("Confirm") }}
            </div>
        </div>
    </div>
</template>

<script>

    import Left from "@/assets/cashwheel/left.png";

    export default {
        name:"Validate",
        components:{
        },
        data(){
            return {
                Left,
                target:""
            }
        },
        methods:{
            toBack(){
                this.$router.go(-1);
            },
            confirm(){
                this.$router.push(this.target)
            }
        },
        mounted(){
            this.target = this.$route.query.target
        },
        beforeDestroy(){
        }
    }
</script>

<style scoped>

    .saveButton{
        box-sizing:border-box;
        overflow:hidden;
        background:var(--gdient2);
        border-radius:1rem;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.3rem;
        font-weight:bold;
        width:100%;
        height:4rem;
        margin-top:1rem;
    }

    .brownSvg path {
        fill:var(--subfont);
    }
    
    .brownSvg{
        margin-right:1rem;
    }

    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.05rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
    .settingInput > input::placeholder{
        color:var(--subfont);
    }

    .textDescription{
        font-size:1.2rem;
        font-weight:bold;
        color:rgba(255, 255, 255, 0.6);
        margin-top:1rem;
        word-break: break-all;
    }

    .textTitle{
        font-size:1.4rem;
        font-weight:bold;
        color:#fff;
    }

    .navBox>span{
        font-size:2rem;
        color:rgba(255,255,255,0.65);
    }
    .goback{
        transform: rotate(180deg);
        opacity: 0.65;
        width: 2rem;
        height: 2rem;
        cursor:pointer;
    }
    .navBox{
        background:var(--primary);
        height:4rem;
        width:100%;
        box-sizing:border-box;
        padding:0 1rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:absolute;
        top:0;
        left:0;
        z-index:10;
    }
    .container{
        height:100%;
        overflow:hidden;
        width:100%;
        position:relative;
    }
    .text{
        height:auto;
        overflow-x:hidden;
        overflow-y:scroll;
        scrollbar-width:none;
        position:relative;
        width:100%;
        height:calc(100% - 4rem);
        padding:2rem 2rem;
        padding-top:6rem;
        background:var(--primary);
        box-sizing:border-box;
        display:flex;
        flex-direction: column;
    }

</style>