<template>
    <div class="levelUpBox">
        <div class="levelUpHeader">
            <svg @click="closeModal" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200"><path d="M548.992 503.744L885.44 167.328a31.968 31.968 0 1 0-45.248-45.248L503.744 458.496 167.328 122.08a31.968 31.968 0 1 0-45.248 45.248l336.416 336.416L122.08 840.16a31.968 31.968 0 1 0 45.248 45.248l336.416-336.416L840.16 885.44a31.968 31.968 0 1 0 45.248-45.248L548.992 503.744z" fill="#5e6f8f"></path></svg>
        </div>
        <div class="levelUpLevelBox">
            <div class="profile2InfoBoxBottomVipItem previous">
                <img :src="previous?.levelIcon" />
                <span>{{ previous?.levelName }}</span>
            </div>
            <IntoAnimate></IntoAnimate>
            <div class="profile2InfoBoxBottomVipItem current">
                <img :src="current?.levelIcon" />
                <span>{{ current?.levelName }}</span>
            </div>
        </div>
        <span class="levelUpBonus">{{ $t("Bonus") }}</span>
        <div class="levelUpBonusBox">
            <img :src="BlackCoin" />
            <span v-text="bonus"></span>
        </div>
        <span class="levelUpDes" @click="closeModal">
            {{ $t("LevelUpTips") }}
        </span>
    </div>
</template>

<script lang="js">

    import IntoAnimate from "@/components/IntoAnimate.vue";

    import BlackCoin from "@/assets/img/blackcoin.png";

    import { mapState } from 'vuex';

    export default {
        name:"LevelUp",
        data(){
            return {
                BlackCoin,
            }
        },
        props:{
            visible:{
                type:Boolean,
                default:false,
            },
            oldLevel:{
                type:Number,
            },
            newLevel:{
                type:Number,
            }
        },
        computed:{
            ...mapState({
                user: state => state.user.user,
                vipList: state => state.user.vipList,
            }),
            current(){
                return this.vipList.find(item => item.level == this.newLevel);
            },
            previous(){
                return this.vipList.find(item => item.level == this.oldLevel);
            },
            bonus(){
                let totalBonus = 0;
                // 获取当前级别和之前的级别之间的所有级别
                let currentIndex = this.vipList.findIndex(item => item.level == this.newLevel);
                let previousIndex = this.vipList.findIndex(item => item.level == this.oldLevel);

                // 如果 newLevel 和 oldLevel 都存在并且 newLevel > oldLevel
                if (currentIndex !== -1 && previousIndex !== -1 && currentIndex > previousIndex) {
                    // 累加从 oldLevel 到 newLevel 之间所有级别的 prizeBalance
                    for (let i = previousIndex + 1; i <= currentIndex; i++) {
                        totalBonus += this.vipList[i].prizeBalance;
                    }
                }

                return totalBonus;
            },
        },
        components:{
            IntoAnimate
        },
        methods:{
            closeModal(){
                this.$emit("update:visible",false);
            }
        }
    }

</script>

<style lang="css" scoped>

    .levelUpHeader svg{
        width:2rem;
        height:2rem;
    }

    .levelUpHeader path{
        fill:var(--icon)
    }

    .levelUpHeader{
        width:100%;
        display:flex;
        height:5rem;
        align-items: center;
        flex-direction: row-reverse;
        box-sizing:border-box;
        padding-right:2rem;
    }

    .levelUpLevelBox{
        display:flex;
        justify-content: space-between;
        align-items: center;
    }

    .previous{
        margin-right:1.5rem;
    }
    
    .current{
        margin-left:1.5rem;
    }

    .profile2InfoBoxBottomVipItem > span {
        color:var(--basic);
        font-weight:bold;
        font-size:1.2rem;
    }

    .profile2InfoBoxBottomVipItem > img {
        height:5rem;
    }

    .profile2InfoBoxBottomVipItem{
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .levelUpDes{
        margin-top:2rem;
        font-size:1.3rem;
        color:var(--subfont);
        font-weight:bold;
    }

    .levelUpBonusBox img {
        width:2rem;
        height:2rem;
    }

    .levelUpBonusBox span {
        font-size:1.5rem;
        color:var(--mainfont);
        font-weight:bold;
        margin-left:0.5rem;
    }

    .levelUpBonusBox{
        width:12rem;
        height:3.5rem;
        background: var(--background);
        border-radius:2rem;
        margin-top:2rem;
        display:flex;
        align-items: center;
        box-sizing:border-box;
        justify-content: center;
    }

    .levelUpBonus {
        color:var(--basic);
        font-weight:bold;
        font-size:1.8rem;
        margin-top:2rem;
    }

    .levelUpBox{
        width:40rem;
        margin:0 auto;
        border-radius:1rem;
        height:fit-content;
        box-sizing:border-box;
        padding:0 0;
        padding-bottom:4rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to bottom, var(--modalgdient), var(--modalgdient2));
    }

</style>