<template>
    <div class="viewBoxGlobal" ref="fatherScroll">
        <div class="box">
            <ClassList ref="classListCom" :nopadding="true">
                <div class="classList">
                    <div @click="changeRoute(item,index)" :class="item.active ? 'classItem active' : 'classItem'" v-for="(item,index) in classList" :key="index">
                        <img :src="item.img" />
                        <span v-text="item.name"></span>
                    </div>
                </div>
            </ClassList>
            <div class="viewBox">
                <router-view>
                </router-view>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

    import Footer from "../components/Footer.vue"
    import Invite from "../assets/img/inviteI.png"
    import Refer from "../assets/img/referI.png"
    import Member from "../assets/img/memberI.png"
    import Bonus from "../assets/img/bonusI.png"

    import ClassList from "@/components/ClassList.vue";

    export default {
        name:"Refer",
        data(){
            return {
                classList:[
                    {
                        name:this.$t("Invite"),
                        path:"/refer/invite",
                        img:Invite,
                        active:true,
                    },
                    {
                        name:this.$t("Refer"),
                        path:"/refer/referc",
                        img:Refer,
                        active:false,
                    },
                    {
                        name:this.$t("Member"),
                        path:"/refer/member",
                        img:Member,
                        active:false,
                    },
                    {
                        name:this.$t("Bonus"),
                        path:"/refer/bonus",
                        img:Bonus,
                        active:false,
                    }
                ],
                classlistscroll:false
            }
        },
        components:{
            Footer,
            ClassList
        },  
        methods:{
            checkScroll() {
                const scrollTop = this.$refs.fatherScroll.scrollTop;
                const targetScroll = parseFloat(getComputedStyle(document.documentElement).fontSize) * 10; // 计算 15rem 的像素值
                this.classlistscroll = scrollTop >= targetScroll; 
                this.$refs.classListCom.changeScrollTop(this.classlistscroll);
            },
            changeRoute(item,index){
                this.classList.forEach((item2, i) => {
                    if (i === index) {
                        item2.active = true;
                    } else {
                        item2.active = false;
                    }
                })
                this.$router.push(item.path)
            },
            init(){
                let newValue = this.$route.path;
                this.classList = this.classList.map(item => {
                    item.active = false;
                    if(newValue.indexOf(item.path) != -1){
                        item.active = true;
                    }
                    return item;
                })
            }
        },
        watch:{
            '$route.path'(newValue){
                this.classList = this.classList.map(item => {
                    item.active = false;
                    if(newValue.indexOf(item.path) != -1){
                        item.active = true;
                    }
                    return item;
                })
            }
        },
        mounted(){
            this.init();
            this.$refs.fatherScroll.addEventListener('scroll', this.checkScroll);
        },
    }
</script>

<style scoped>
    .box{
        background: var(--background);
        box-sizing:border-box;
        padding-top:8rem;
        padding-bottom:8rem;
        box-sizing:border-box;
        padding:1rem 1rem;
    }
    .classList{
        display:flex;
        background-image: linear-gradient(to top,var(--primary),var(--background));
        height:8rem;
        width:100%;
        border-radius:1rem;
        overflow:hidden;
        margin-top:1rem;
    }
    .classItem{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        width:25%;
        border-radius:1rem;
    }
    .classItem img{
        height:3rem;
    }
    .classItem span{
        font-size:1.5rem;
        margin-top:1rem;
        color:var(--subfont);
        font-weight:bold;
    }
    .classItem.active{
        background-image: linear-gradient(to top,var(--backgroundactive),var(--background));
    }
    .classItem.active span{
        color:#fff;
    }
    .viewBox{
        height:fit-content;
        overflow:hidden;
        width:100%;
    }
</style>