<template>
    <transition name="fade">
        <div class="fixedDownloadAPP" v-show="$store.state.user.appEnable">
            <div class="fixedDownloadAPPLeft">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path fill="#36AB60" d="M501.76 415.5392v86.016h24.9856c33.792 0 50.5856-14.5408 50.5856-43.4176 0-28.2624-16.7936-42.5984-50.176-42.3936l-25.3952-0.2048z m335.0528-111.616V63.8976C836.8128 28.672 807.7312 0 771.8912 0H253.1328C217.2928 0 188.416 28.672 188.416 63.8976v240.0256H67.3792v399.9744h121.0368v256c0 35.2256 29.0816 63.8976 64.7168 63.8976H771.8912c35.84 0 64.9216-28.672 64.9216-63.8976v-256h119.808V303.9232h-119.808zM771.8912 80.0768v224.0512H253.1328V80.0768H771.8912z m-134.144 375.6032c0 27.2384-10.24 49.152-30.3104 65.536-20.2752 16.384-45.4656 24.3712-75.9808 23.7568H501.5552v92.7744h-57.344v-266.24h92.7744c67.1744 0 100.5568 28.0576 100.7616 84.1728zM221.5936 576.7168l-19.8656 61.0304H139.4688l98.7136-266.24h67.584l96.8704 266.24H339.7632l-20.0704-60.8256-98.0992-0.2048z m291.0208 415.3344c-17.8176 0.2048-32.3584-14.1312-32.3584-31.9488 0-17.6128 14.5408-31.9488 32.3584-31.9488 18.0224 0 32.3584 14.336 32.3584 31.9488s-14.5408 31.9488-32.3584 31.9488zM771.8912 896H253.1328v-191.8976H771.8912v191.8976z m62.2592-374.784c-20.2752 16.384-45.4656 24.3712-75.9808 23.7568h-29.696v92.7744h-57.5488v-266.24H763.6992c67.1744 0 100.7616 28.0576 100.7616 84.1728 0 27.2384-10.0352 49.152-30.3104 65.536z m-80.2816-105.6768h-25.3952v86.016H753.4592c33.792 0 50.5856-14.5408 50.5856-43.4176 0-28.4672-16.5888-42.5984-50.176-42.5984z m-482.5088 1.8432h-1.6384c-0.6144 7.5776-2.2528 15.1552-4.7104 22.528l-30.9248 92.9792h71.8848l-30.72-92.3648c-2.048-7.5776-3.4816-15.36-3.8912-23.1424z"></path>
                </svg>
                <span>
                    Baixe agora para receberum b'nus gratuito!
                </span>
            </div>
            <div class="fixedDownloadAPPRight">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" @click="closeHeader">
                    <path d="M512 870.4c-197.93 0-358.4-160.47-358.4-358.4S314.07 153.6 512 153.6 870.4 314.07 870.4 512 709.93 870.4 512 870.4z m0 89.6c247.424 0 448-200.576 448-448S759.424 64 512 64 64 264.576 64 512s200.576 448 448 448z"></path>
                    <path d="M686.23 686.23a44.8 44.8 0 0 1-63.36 0l-285.1-285.1a44.8 44.8 0 0 1 63.36-63.36l285.1 285.1a44.8 44.8 0 0 1 0 63.36z"></path>
                    <path d="M686.23 337.77a44.8 44.8 0 0 1 0 63.36l-285.1 285.1a44.8 44.8 0 0 1-63.36-63.36l285.1-285.1a44.8 44.8 0 0 1 63.36 0z"></path>
                </svg>
                <div class="fixedDownloadAPPButton buttonTransition" @click="toDownload">
                    {{ $t("Download") }}
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="js">

    import EventBus from "@/main";

    export default {
        name:"FixedDownloadAPP",
        data(){
            return {
                
            }
        },
        methods:{
            closeHeader(){
                this.$store.dispatch("closeAPPDownloadUserClick") ;
            },
            toDownload(){
                EventBus.$emit("downloadAPP")
            }
        },
        mounted(){
        }
    }

</script>

<style lang="css">
    .fade-enter-active, .fade-leave-active {
        transition: height 0.5s ease-in-out;
    }

    .fade-enter, .fade-leave-to {
        height:0rem
    }

    .fade-enter-to, .fade-leave {
        height:6rem
    }
</style>

<style lang="css" scoped>

    .fixedDownloadAPP{
        width:100%;
        background:var(--background);
        z-index:999;
        box-sizing:border-box;
        padding:0 1.5rem;
        display:flex;
        align-items: center;
        overflow:hidden;
    }

    .fixedDownloadAPPLeft{
        height:100%;
        display:flex;
        align-items: center;
        width:50%;
    }

    .fixedDownloadAPPLeft > svg {
        height:6rem;
        width:3rem;
    }

    .fixedDownloadAPPLeft > span {
        color:var(--mainfont);
        margin-left:1rem;
        display:block;
        word-break: break-all;
        width:100%;
        flex:1;
        font-weight:bold;
        font-size:1.2rem;
    }

    .fixedDownloadAPPRight > svg {
        height:3rem;
        width:3rem;
    }

    .fixedDownloadAPPRight path {
        fill:var(--icon);   
    }

    .fixedDownloadAPPRight{
        height:100%;
        display:flex;
        align-items: center;
        width:50%;
        flex-direction: row-reverse;
    }

    .fixedDownloadAPPButton{
        background: linear-gradient(to top right, var(--gdient1), var(--gdient2));
        color:var(--basic);
        width:10rem;
        height:3rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
        border-radius:0.5rem;
        margin-right:1rem;
        font-size:1.1rem;
    }



</style>