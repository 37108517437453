<template>
    <div id="app">
        <keep-alive
            :include="['Promotion', 'Inbox','Home','Deposit']"
        >
            <router-view></router-view>
        </keep-alive>
        <img style="position:fixed;z-index: 0;top:-200rem;" v-for="item in vipList" :src="item.levelBg" :key="item.id" />
        <img style="display:none;" :src="currentCategoryBg" />
        
        <MainLoading v-loading="mainloading" v-show="mainloading"></MainLoading>
        <Service></Service>
        <Tabbar v-show="isExist"></Tabbar>
        <Header v-show="isExistHeader"></Header>
        <Loading v-show="isLoading"></Loading>

        <Modal :visible="confirmVisible" :gradient="true" :padding="false" :widths="80">
            <div class="transferBox">
                <span>{{ $t("NoActionTip") }}</span>
                <div class="transferOption">
                    <div class="closeButton" @click="closeRetryModal">
                        {{ $t("Cancel") }}
                    </div>
                    <div class="queryButton" @click="refresh">
                        {{ $t("Refresh") }}
                    </div>
                </div>
            </div>
        </Modal>
        <Modal :visible="ageVisible" :gradient="true" :padding="false" :widths="80">
            <div class="transferBox">
                <span>{{ $t("AgeQuestion") }}</span>
                <div class="transferOption">
                    <div class="closeButton" @click="cancelAge">
                        {{ $t("AgeCancel") }}
                    </div>
                    <div class="queryButton" @click="confirmAge">
                        {{ $t("AgeConfirm") }}
                    </div>
                </div>
            </div>
        </Modal>
        <Modal :visible="tipsVisible" :gradient="true" :padding="false" :widths="80">
            <div class="transferBox">
                <span>{{ $t("AgeTips") }}</span>
                <div class="transferOption">
                    <div class="closeButton" @click="refresh">
                        {{ $t("OK") }}
                    </div>
                </div>
            </div>
        </Modal>
        <Modal :visible.sync="levelupVisible" :gradient="false" :padding="false" :widths="100" :hasBg="false">
            <LevelUp :visible.sync="levelupVisible" :oldLevel="oldLevel" :newLevel="newLevel"></LevelUp>
        </Modal>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Tabbar from "@/components/Tabbar.vue";
import Modal from "@/components/Modal.vue";
import Loading from "@/components/Loading.vue";
import MainLoading from "@/components/MainLoading.vue";
import LevelUp from "@/components/LevelUp.vue";
import Service from "@/components/Service.vue";

import EventBus from "./main";

import { GetUserMessageUnReadCountApi,GetAjuda } from "@/api/base.js";
import { getClassList,getStationImgList,GetPopUp } from "@/api/home";
import { getConfig,HeartBeat } from "@/api/base";
import changeRem, {isPcDevice} from "@/basic/rem.js";
import { mapState } from 'vuex';
import { PayMethod } from "@/api/deposit";
import { GetLevel } from "@/api/vip.js";

import textureBg from "@/assets/texture_bg.png";

export default {
    name: 'App',
    components: {
        Tabbar,
        Modal,
        Loading,
        Header,
        MainLoading,
        LevelUp,
        Service
    },
    data() {
        return {
            isLoading: false,
            textureBg,
            confirmVisible:false,
            isIdle: false,
            idleTimer: null,
            idleTime:1800000, // 三十分钟
            // idleTime: 2000,
            mainloading:true,
            ageVisible:false,
            tipsVisible:false,
            levelupVisible:false,
            oldLevel:0,
            newLevel:0,
            
            heartBeatInterval:null,
        }
    },
    async mounted() {
        let res = {
            data:{}
        }
        // 检查路径是否带了agentid
        if(this.$route.query.agentid){
            // 保存代理id并且在注册的时候传入这个id
            this.$store.dispatch("setAgentId",this.$route.query.agentid);
        }
        let [classListRes, configRes, stationImgList,payRes,withdrawRes,noticeRes,ajudaRes,vipRes] = await Promise.all([
            getClassList(),
            getConfig(),
            getStationImgList(),
            PayMethod({}),
            PayMethod({channelType:1}),
            GetPopUp(),
            GetAjuda(), 
            GetLevel()
        ]);
        // 处理 getClassList 响应
        this.$store.dispatch("setAjuda",ajudaRes.data || []);
        this.$store.dispatch("setAllNoticeList",noticeRes.data || []);
        this.$store.dispatch("setGame",classListRes.data.game || []);
        this.$store.dispatch("setCategory",classListRes.data.category || []);
        this.$store.dispatch("setStationImgList",stationImgList.data || []);
        // this.$store.dispatch("setStationImgList",[] || []);
        this.$store.dispatch("setTabbar",configRes.data.bottomTabBar || []);
        this.$store.dispatch("setLogo",configRes.data.fullStationLogo || "");
        this.$store.dispatch("setNotice",configRes.data.marquee || []);
        this.$store.dispatch("setRecent",{
            recentGameRecordEnabled:configRes.data.recentGameRecordEnabled || "",
            // recentGameRecordEnabled:false,
            recentGameRecordIcon:configRes.data.fullRecentGameRecordIcon || "",
            recentGameRecordLabel:configRes.data.recentGameRecordLabel || "",
        });
        this.$store.dispatch("setLiveChatLicense",configRes.data.onlineCustomerLicense || "");
        this.$store.dispatch("setPayConfig",payRes.data || []);
        this.$store.dispatch("setWithdrawConfig",withdrawRes?.data[0]?.payChannels[0]?.payConfig || []);
        this.$store.dispatch("setVIPList",vipRes.data || []);
        this.$store.dispatch("setCPFEnable",configRes.data.cpf || false);
        this.$store.dispatch("setAPPDownload",configRes.data.isAppDownload || false);
        this.$store.dispatch("setAPPDownloadTip",configRes.data.isAppDownloadTip || false);

        EventBus.$emit("checkRoute");

        // 处理 getConfig 响应
        document.title = configRes.data.stationName;

        const faviconLink = document.querySelector("link[rel='icon']");
        if (faviconLink) {
            faviconLink.href = configRes.data.fullStationIco;
        } else {
            // 如果 link 标签不存在，创建一个新的 link 标签并添加到头部
            const newFaviconLink = document.createElement('link');
            newFaviconLink.setAttribute('rel', 'icon');
            newFaviconLink.href = configRes.data.fullStationIco;
            document.head.appendChild(newFaviconLink);
        }

        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', configRes.data.content);
        } else {
            // 如果 meta 标签不存在，创建一个新的 meta 标签并添加到头部
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute('name', 'description');
            newMetaTag.setAttribute('content', configRes.data.content);
            document.head.appendChild(newMetaTag);
        }

        this.viewKey += 1;

        this.initUserMessage();
        let mode = configRes.data.theme;
        switch(mode){
            case "default":
                res.data = {
                    "--primary" : "#161f2c",
                    "--background" : "#0d131c",
                    "--backgroundactive" : "#193152",
                    "--subbackground" : "#1f2938",
                    "--subbackgroundspecial" : "#26303f",
                    "--basic" : "#ffffff",
                    "--special" : "#ACC3EF",
                    "--mainfont" :"#c5d8ff",
                    "--title":"#c5d8ff",
                    "--subfont" : "#58677c",
                    "--icon" : "#ACC3EF",
                    "--gdient1" : "#a113d7",
                    "--gdient2" : "#4630c5",
                    "--success" : "#0fb125",
                    "--error" : "#dd4b4b",
                    "--warning" : "#f3dc38",
                    "--blue" : "#0968dc",
                    "--footerb" : "#15242b",
                    "--tabbar":"#304260",
                    "--tabbar2":"#1b2a3b",
                    "--modalgdient":"#0b2b62",
                    "--modalgdient2":"#0f1721",
                    "--pricebutton":"#0fb125"
                }
                break;
            case "golden":
                res.data = {
                    "--primary" : "#141414",
                    "--background" : "#0a0a0a",
                    "--backgroundactive" : "#523a19",
                    "--subbackground" : "#222222",
                    "--subbackgroundspecial" : "#393939",
                    "--basic" : "#ffffff",
                    "--mainfont" :"#ffecc7",
                    "--title":"#ffecc7",
                    "--subfont" : "#8d8d8d",
                    "--special" : "#ffecc7",
                    "--icon" : "#e0ceab",
                    "--gdient1" : "#a5663c",
                    "--gdient2" : "#845230",
                    "--success" : "#0fb125",
                    "--error" : "#dd4b4b",
                    "--warning" : "#f3dc38",
                    "--blue" : "#0968dc",
                    "--footerb" : "#130402",
                    "--tabbar":"#5f4e30",
                    "--tabbar2":"#3b2c1b",
                    "--modalgdient":"#60160b",
                    "--modalgdient2":"#3a0d07",
                    "--pricebutton":"#0fb125"
                }
                break;
            case "black_green":
                res.data = {
                    "--primary" : "#222222",
                    "--background" : "#111111",
                    "--backgroundactive" : "#12803a",
                    "--subbackground" : "#333333",
                    "--subbackgroundspecial" : "#393939",
                    "--basic" : "#ffffff",
                    "--mainfont" :"#ffffff",
                    "--title":"#c4f2d2",
                    "--subfont" : "#747474",
                    "--special" : "#ffffff",
                    "--icon" : "#c4f2d2",
                    "--gdient1" : "#28aa4e",
                    "--gdient2" : "#28aa4e",
                    "--success" : "#0fb125",
                    "--error" : "#dd4b4b",
                    "--warning" : "#f3dc38",
                    "--blue" : "#0968dc",
                    "--footerb" : "#021108",
                    "--tabbar":"#202020",
                    "--tabbar2":"#202020",
                    "--modalgdient":"#1e3c29",
                    "--modalgdient2":"#062b13",
                    "--pricebutton":"#cabb52"
                }
                break;
            case "white_blue":
                res.data = {
                    "--primary" : "#FFFFFF",
                    "--background" : "#f2f2f2",
                    "--backgroundactive" : "#52a6fd",
                    "--subbackground" : "#fafafa",
                    "--subbackgroundspecial" : "#e1e1e1",
                    "--basic" : "#000000",
                    "--mainfont" :"#1e87f0",
                    "--subfont" : "#666666",
                    "--special" : "#29d15b",
                    "--icon" : "#1370ed",
                    "--gdient1" : "#52a6fd",
                    "--gdient2" : "#1370ed",
                    "--success" : "#0fb125",
                    "--error" : "#dd4b4b",
                    "--warning" : "#ffd700",
                    "--blue" : "#0968dc",
                    "--footerb" : "#FFFFFF",
                    "--tabbar":"#FFFFFF",
                    "--tabbar2":"#FFFFFF",
                    "--modalgdient":"#52a6fd",
                    "--modalgdient2":"#1370ed",
                    "--pricebutton":"#0fb125"
                }
                break;
            default:
                res.data = {
                    "--primary" : "#161f2c",
                    "--background" : "#0d131c",
                    "--backgroundactive" : "#193152",
                    "--subbackground" : "#1f2938",
                    "--subbackgroundspecial" : "#26303f",
                    "--basic" : "#ffffff",
                    "--mainfont" :"#c5d8ff",
                    "--subfont" : "#58677c",
                    "--special" : "#ACC3EF",
                    "--icon" : "#ACC3EF",
                    "--gdient1" : "#a113d7",
                    "--gdient2" : "#4630c5",
                    "--success" : "#0fb125",
                    "--error" : "#dd4b4b",
                    "--warning" : "#f3dc38",
                    "--blue" : "#0968dc",
                    "--footerb" : "#15242b",
                    "--tabbar":"#304260",
                    "--tabbar2":"#1b2a3b",
                    "--modalgdient":"#0b2b62",
                    "--modalgdient2":"#0f1721",
                    "--pricebutton":"#0fb125"
                }
                break;
        }
        for(let item in res.data){
            document.documentElement.style.setProperty(item,res.data[item])
        }

        this.mainloading = false;
        localStorage.setItem("finishModal",true)
        EventBus.$emit("checkAdv")
        EventBus.$emit("updateBanner")
        
        if(configRes.data.isAge18Plus){
            if(!localStorage.getItem("isAge18Plus")){
                this.ageVisible = true;
            }
        }

        document.documentElement.style.backgroundImage = "url('" + textureBg +"')";
        document.body.style.background = 'var(--primary) !important';
        document.documentElement.style.backgroundColor = 'var(--primary) !important';

        this.resetTimer();
        window.addEventListener('mousemove', this.resetTimer);
        window.addEventListener('click', this.resetTimer);
        window.addEventListener('keydown', this.resetTimer);
        
    },
    created() {
        EventBus.$on('heartBeat', () => {
            this.heartBeatInterval = setInterval(() => {
                HeartBeat()
            },180000);
            HeartBeat()
        });
        EventBus.$on('heartBeatUnInstall', () => {
            clearInterval(this.heartBeatInterval)
        });
        if(this.$store.state.user.token){
            EventBus.$emit("heartBeat");
        }
        this.$nextTick(() => {
            // 禁用右键
            document.oncontextmenu=function(){return false;}
            // 禁用选择
            document.onselectstart=function(){return false;}
        });

        const setRem = () => {
            const baseWidth = 375;
            const baseHeight = 667;

            function calculateWidthByRatio(currentHeight, targetWidth, targetHeight) {
                const ratio = targetWidth / targetHeight;
                const newWidth = currentHeight * ratio;
                return newWidth;
            }

            this.$nextTick(() => {
                const documentHeight = document.documentElement.offsetHeight;
                const newWidth = parseInt(calculateWidthByRatio(documentHeight, baseWidth, baseHeight));

                if (isPcDevice()) {
                    document.body.style.maxWidth = newWidth + 'px';
                    document.body.style.minWidth = newWidth + 'px';
                }

                EventBus.$emit("change-tabbar")
                EventBus.$emit("change-header")

                changeRem();
            })
        }
        setRem();
        // 监听窗口大小变化，动态调整 rem
        window.addEventListener('resize', setRem);
        // 监听全局事件，显示 loading
        EventBus.$on('show-loading', () => {
            this.isLoading = true;
        });
        // 监听全局事件，隐藏 loading
        EventBus.$on('hide-loading', () => {
            this.isLoading = false;
        });
    },
    methods: {
        cancelAge(){
            this.ageVisible = false;
            this.tipsVisible = true;
        },
        confirmAge(){
            this.ageVisible = false;
            localStorage.setItem("isAge18Plus",true);
        },
        resetTimer() {
            this.isIdle = false;
            if (this.idleTimer) {
                clearTimeout(this.idleTimer);
            }
            this.idleTimer = setTimeout(() => {
                this.isIdle = true;
                this.handleIdle();
            }, this.idleTime);
        },
        handleIdle() {
            this.confirmVisible = true;
        },
        closeRetryModal(){
            this.confirmVisible = false;
            this.resetTimer();
        },
        refresh(){
            this.confirmVisible = false;
            window.location.reload();
        },
        initUserMessage(){ // 初始化用户的消息
            if(this.$store.state.user.token){
                GetUserMessageUnReadCountApi().then(res => {
                    this.$store.dispatch("setUserMessage",res.data || 0);
                })
            }
        }
    },
    watch: {
        token(newToken, oldToken) {
            this.initUserMessage();
        },
        user:{
            handler(newVal,oldVal){
                if (newVal.level > oldVal.level) {
                    this.oldLevel = oldVal.level;
                    this.newLevel = newVal.level;
                    this.levelupVisible = true;
                    this.$forceUpdate();
                }
            },
            deep:true
        }
    },
    computed: {
        isExistHeader(){
            let noExist = ["/login", "/deposit", "/region", "/", '/load', '/other',"/game","/cashwheel","/validate","/setphone","/help"]
            return noExist.findIndex(item => item == this.$route.path) == -1;
        },
        isExist() {
            let noExist = ["/login", "/deposit", "/region", "/", '/load', '/other',"/game","/cashwheel","/validate","/setphone","/help"]
            return noExist.findIndex(item => item == this.$route.path) == -1;
        },
        ...mapState({
            token: state => state.user.token,
            currentCategoryBg: state => state.user.currentCategoryBg,
            vipList: state => state.user.vipList,
            user: state => state.user.user
        })
    },
    beforeDestroy(){
        clearInterval(this.heartBeatInterval);
    }
}
</script>

<style scoped>

    .queryButton{
        width:16rem;
        height:4rem;
        background-color:var(--success);
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .closeButton{
        width:16rem;
        height:4rem;
        background-color:var(--error);
        color:#fff;
        display:flex;
        align-items: center;
        margin-right:3rem;
        justify-content: center;
        font-size:1.6rem;
        font-weight:bold;
        border-radius:1.5rem;
    }
    .transferOption{
        display:flex;
        justify-content: center;
        margin-top:2rem;
    }
    .transferBox>span{
        color:var(--basic);
        font-size:1.7rem;
        font-weight:bold;
        word-break: break-all;
        text-align: center;
    }
    .transferBox{
        display:flex;
        flex-direction: column;
        box-sizing:border-box;
        padding:2rem 2rem;
    }

html{
    --background: transparent;
    background-image: var(--color-screen-bg-img);
    background-size: 5rem;
    background-repeat: repeat;
}

#app {
    height: 100%;
}

</style>

<style>

.formButton .el-loading-spinner .circular{
    /* height:2rem !important; */
    width:2rem !important;
}
.searchButton .el-loading-spinner .circular{
    height:1.5rem !important;
    width:1.5rem !important;
}
.searchButton .el-loading-spinner{
    margin-top: -0.75rem !important;
}
.LoginButton .el-loading-spinner .circular{
    height:2rem !important;
    width:2rem !important;
}
.LoginButton .el-loading-spinner{
    margin-top: -1rem !important;
}


.el-loading-mask{
    background:#161f2c !important;
}
.el-loading-spinner .path {
    stroke:#fff !important;
    stroke-width: 5px !important;
}

.skeleton path {
    fill:var(--icon) !important;
}

.skeleton > svg {
    width: 50%;
    height: 50%;
}

.skeleton {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--primary);
	background-color: var(--primary);
	background: linear-gradient(90deg, var(--primary) 25%, var(--icon) 37%, var(--primary) 50%);
	background-size: 400% 100%;
	animation: mcnSkeleton 1.8s ease infinite
}
 
@keyframes mcnSkeleton {
	0% {    
		background-position: 100% 50%
	}
	70% {
		background-position: 0 50%
	}
}

.noneDisplay{
    display:none !important;
}

.chat-widget-open{
    z-index:2000 !important;
}

.chat-widget-close{
    z-index:1900 !important;
}

#chat-widget-container{
    overflow:visible !important;
}

#chat-widget-minimized{
    position:absolute !important;
    right: 1.5rem !important;
    display:none !important;
}

.allPageBox {
    overflow-y: scroll !important;
    scrollbar-width: none;
    -webkit-overflow-scrolling: unset;
    height: 100vh !important;
}

.allPageBox::-webkit-scrollbar {
    display: none;
}

.lang-select-dropdown {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}

.viewBoxGlobal {
    height: calc(100vh);
    /* overflow-y: scroll !important; */
    overflow-y:scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: unset;
    box-sizing: border-box;
    padding: 8rem 0;
}

.viewBoxGlobalSpecial {
    height: calc(100vh);
    /* overflow-y: scroll !important; */
    overflow-y:scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: unset;
    box-sizing: border-box;
    padding: 14rem 0;
}

.viewBoxGlobal::-webkit-scrollbar {
    display: none;
}

* {
    font-family: "微软雅黑";
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.buttonTransition:active {
    transform: scale(1.1);
    -webkit-transform: scale(1.1); /* 为Safari添加前缀 */
}

.buttonTransition {
    transition: all 0.05s;
}

.swiper-pagination-bullet {
    background-color: #fff !important; /* 设置小圆点的背景颜色为白色 */
}

body, html {
    background:#161f2c;
    padding: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    box-shadow:0px 0px 1rem rgba(0,0,0,0.4);
}

.el-dropdown-menu {
    position: absolute !important;
}

.el-form-item__content {
    line-height: none !important;
    font-size: none !important;
    display:flex;
    align-items: center;
}

.el-form-item__error {
    display: none !important;
}

.el-form-item {
    margin-bottom: 0 !important;
}

.el-input__inner {
    background: none !important;
}

.el-range-input {
    background: none !important;
}

.el-range-input::placeholder {
    color: var(--placeholder) !important;
}

.el-input__inner {
    border-color: var(--placeholder) !important;
    border-radius: 1rem !important;
}

.el-range__icon {
    color: var(--placeholder) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.el-input__inner {
    border-color: var(--value) !important;
    color: var(--value) !important;
    font-weight: bold;
}

.el-input__inner::placeholder {
    color: var(--value) !important;
    font-weight: bold;
}

.el-select__caret {
    color: var(--value) !important;
}

.el-dropdown-menu {
    background: var(--primary) !important;
    border: none !important;
}

.popper__arrow {
    border: none !important;
}

.popper__arrow::after {
    border-bottom-color: var(--primary) !important;
}

.el-message {
    background: var(--gdient2) !important;
    color: #fff !important;
    min-width: 0 !important;
    width: fit-content !important;
    border: none !important;
    padding: 1.5rem 2rem !important;
    max-width:80% !important;
}

.el-message--success {
    background: var(--success) !important;
}

.el-message i {
    color: #fff !important;
    display: none !important;
}

.el-message p {
    color: #fff !important;
    /* white-space: nowrap; */
    word-break: break-all !important;
    overflow:hidden !important;
    white-space:nowrap !important;
    text-overflow: ellipsis !important;
}

/* 1f2938 */
.el-select-dropdown {
    background: var(--subbackground) !important;
    border: none !important;
}

.el-select-dropdown__item.selected {
    color: #fff !important;
}

.el-select-dropdown__item {
    color: var(--popovertext) !important;
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background: var(--pophover) !important;
}

.el-date-editor {
    width: fit-content !important;
}

.el-date-editor input {
    border: none !important;
    height: 100% !important;
    font-size: 1.3rem !important;
    line-height: normal !important;
    width: 10rem !important;
}

.el-input--suffix .el-input__inner {
    padding-right: 1rem !important;
}

.el-input--prefix .el-input__inner {
    padding-left: 1rem !important;
}

.el-date-table th {
    color: var(--subfont) !important;
    border: none !important;
}

.el-picker-panel {
    background: var(--primary) !important;
    border: none !important;
}
.el-picker-panel span {
    color: var(--basic) !important;
}
.el-date-table td.today span{
    color:var(--gdient2) !important;
}
.el-date-table td.current:not(.disabled) span{
    background:var(--gdient2) !important;
    color:#fff !important;
}
.el-date-picker__header--bordered {
    border: none !important;
}

.next-month span {
    color: var(--subfont) !important;
}

.el-date-picker__header button::before {
    color: var(--subfont) !important;
}

.el-dropdown-menu {
    z-index: 999999999 !important;
}

.el-message {
    z-index: 999999999 !important;
}
.el-popover{
    z-index: 999999999 !important;
}

.el-switch__core {
    background: var(--subbackground) !important;
    border-color: var(--subbackground) !important;
}

.el-switch.is-checked .el-switch__core {
    background: var(--success) !important;
}

</style>

