<template>
    <div class="container">
        <div class="arrowItem">
            <div class="arrow"></div>
            <div class="arrow"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"IntoAnimate",
        props:{
        },
        data(){
            return {

            }
        },
        methods:{

        }
    }
</script>

<style scoped>
    .container {
        width: fit-content; /* 箭头宽度加上间距 */
        height: fit-content;
        position: relative;
        box-sizing:border-box;
        display:flex;
        flex-direction: column;
        margin:0 0.7rem;
    }
    .arrowItem{
        height:fit-content;
        width:fit-content;
        display:flex;
        align-items: center;
    }
    .arrowItem:last-child{
        margin-bottom:0 !important;
    }
    .arrow {
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-right: 1rem solid var(--subfont); /* 箭头颜色 */
        transform: rotate(180deg);
        opacity: 0; /* 初始设置透明度为0 */
        animation: fadeInOut 2s infinite; /* 指示箭头动画 */
    }

    @keyframes fadeInOut {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
    }

    .arrow:nth-child(2) {
        margin-left:0.5rem; /* 第二个箭头向右偏移60px */
        animation-delay: 1s; /* 第二个箭头延迟1s开始动画 */
    }
</style>