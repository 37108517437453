<template>
    <div class="container">
        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
            <path d="M834.787792 868.156141h-22.804757c0.659958-83.014254-1.792532-175.169996-50.991368-263.259542-14.336-25.653222-46.750603-34.850062-72.420856-20.509805-25.65748 14.331742-34.845805 46.754861-20.509805 72.416599 36.276424 64.965455 38.103019 136.130262 37.472865 211.357006H352.660491c-1.234761-100.249813-0.774919-220.770994 56.356191-278.638703 25.227443-25.559551 61.512383-37.9838 110.915592-37.9838l7.74919-0.383202c77.172557-0.340624 139.753647-24.124674 186.023118-70.696449 89.660674-90.239734 94.386827-241.919468 93.973821-360.605538h27.100874c29.38731 0 53.222453-23.830886 53.222453-53.222453s-23.835143-53.222453-53.222453-53.222454H211.127085c-29.395825 0-53.222453 23.830886-53.222453 53.222454s23.826628 53.222453 53.222453 53.222453h22.851593c-0.583318 86.420491 1.996906 182.105946 50.527268 270.723459a53.20968 53.20968 0 0 0 72.246287 21.122928c25.780956-14.12311 35.237522-46.465331 21.118669-72.246287C341.905297 273.776299 339.904133 196.156674 340.4321 119.848449h360.801397c0.340624 99.896416-3.210378 225.365156-63.036674 285.583168-26.266345 26.432399-62.968549 39.282428-112.222736 39.282429l-7.744931 0.378944c-77.45783 0.336366-139.677006 23.767019-184.967185 69.640515-87.318886 88.468491-88.434428 239.07526-87.054902 353.422636h-35.079984c-29.395825 0-53.222453 23.830886-53.222453 53.222454 0 29.38731 23.826628 53.222453 53.222453 53.222453H834.787792c29.391568 0 53.222453-23.835143 53.222453-53.222453 0-29.391568-23.830886-53.222453-53.222453-53.222454z" fill="var(--white6)">
            </path>
        </svg>
        <span>{{ $t("Loading") }}...</span>
    </div>
</template>

<script>
    export default {
        name:"Loading",
        data(){
            return {

            }
        },
        methods:{
            
        }
    }
</script>

<style scoped>
    .container{
        background:rgba(0,0,0,0.4);
        box-sizing:border-box;
        padding:1rem 2rem;
        border-radius:1rem;
        position:fixed;
        display:flex;
        flex-direction: column;
        align-items: center;
        z-index:99999999999;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
    .container>svg{
        height:7rem;
        width:4rem;
    }
    .container>span{
        color:var(--white6);
        font-size:1.8rem;
    }
</style>