<template>
    <div class="container">
        <div class="helpBox" :style="isShow ? 'height:fit-content' : 'height:3rem'">
            <div class="clickBox">
                <span>{{ $t("Help") }}</span>
                <svg @click="changeShow" class="icon" :style="isShow ? 'transform: rotate(180deg)' : ''" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path d="M464.384 741.12l2.56 2.773333a64 64 0 0 0 90.538667 0l331.861333-331.861333a64 64 0 0 0-90.496-90.496l-286.549333 286.506667-286.933334-286.933334a64 64 0 1 0-90.453333 90.538667l329.472 329.472z" fill="var(--subfont)">
                    </path>
                </svg>
            </div>
            <div class="helpList">
                <span v-for="item in ajuda" :key="item.id" v-text="item.noticeTitle" @click="toHelp(item)"></span>
            </div>
        </div>
        <div class="shareBox">
            <div class="shareItem" v-for="item in stationimglist.filter(item2 => item2.imgType == 5)" :key="item.id">
                <img :src="item.fullImgUrl" />
            </div>
        </div>
        <div class="sponsorBox">
            <div class="sponsorItem" v-for="item in stationimglist.filter(item2 => item2.imgType == 4)" :key="item.id">
                <img :src="item.fullImgUrl" alt="" />
            </div>
        </div>
        <div class="footerCopyRight">
            <span>Copyright @ 2024</span>
            <div class="footerSpec"></div>
            <span>
                @todos os direitos reservados<br/>
                2002-2024
            </span>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {
        name:"Footer",
        data(){
            return {
                isShow:false,
            }
        },
        computed:{
            ...mapState({
                stationimglist: state => state.user.stationimglist,
                noticeAllList: state => state.user.noticeAllList,
                ajuda:state => state.user.ajuda,
            })
        },
        methods:{
            changeShow(){
                this.isShow = !this.isShow
            },
            toHelp(item){
                this.$router.push({
                    path:"/help",
                    query:{
                        name:item.noticeTitle
                    }
                })
            }
        }
    }
</script>

<style scoped>

    .footerCopyRight > span {
        width:50%;
        display:flex;
        box-sizing:border-box;
        word-break: break-all;
        justify-content: center;
    }

    .footerSpec{
        width:0.03rem;
        height:2rem;
        background:var(--subfont);
        margin:0 2rem;
    }

    .footerCopyRight{
        display:flex;
        justify-content: space-between;
        font-size:1.1rem;
        color:var(--subfont);
        font-weight:bold;
        box-sizing:border-box;
        padding:0 1rem;
    }

    .sponsorItem{
        width:20%;
        height:4rem;
        margin-right:2rem;
    }
    .sponsorItem>img{
        width:100%;
    }
    .sponsorBox{
        box-sizing:border-box;
        padding:2rem 1rem;
        display:flex;
        flex-wrap:wrap;
        padding-bottom:1rem;
    }
    .shareItem{
        width:4rem;
        height:4rem;
        border-radius:50%;
        background:var(--subfont);
        margin-right:2rem;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .shareItem img{
        height:100%;
        width:100%;
    }
    .shareItem svg{
        height:2.5rem;
        width:2.5rem;
    }
    .shareItem svg path{
        fill:var(--sub30black)
    }
    .shareItem:last-child{
        margin-right: 0;
    }
    .shareBox{
        height:7rem;
        border-bottom:1px solid var(--sub31black);
        display:flex;
        box-sizing: border-box;
        justify-content: center;
        padding:0 4rem;
        align-items: center;
    }
    .helpList span:last-child{
        margin-bottom:0;
    }
    .helpList span{
        color:var(--subfont);
        font-size:1.8rem;
        margin-bottom:3rem;
        font-weight:bold;
    }
    .helpList{
        box-sizing: border-box;
        padding:2rem 1rem;
        display:flex;
        flex-direction: column;
    }
    .clickBox{
        display:flex;
        justify-content: space-between;
    }
    .clickBox span{
        color:var(--mainfont);
        font-size:1.8rem;
    }
    .clickBox svg{
        width:2rem;
        height:2rem;
    }
    .helpBox{
        overflow:hidden;
    }
    .container{
        padding:2rem;
        background:var(--footerb);
        padding-bottom:16rem;
    }
</style>
