<template>
    <div>
        <LiveChatWidget ref="livechat" v-if="license" :license="license" class="contact" :visibility="size" @ready="ready" @visibility-changed="listen" />
    </div>
</template>

<script>
    import { LiveChatWidget } from '@livechat/widget-vue'
    import { mapState } from 'vuex';
    import EventBus from "@/main";

    export default {
        name:"ServiceCom",
        data(){
            return {
                size:"minimized"
            }
        },
        computed:{
            ...mapState({
                license: state => state.user.license,
                token: state => state.user.token
            }),
            stationimglist(){
                return this.$store.state.user.stationimglist
            },
        },
        components:{
            LiveChatWidget
        },
        watch:{
            license(){
                this.initContact();
            }
        },
        methods:{
            ready(){
                document.getElementById("chat-widget-container").classList.add('chat-widget-close');
                document.getElementById("chat-widget-container").classList.remove('chat-widget-open');
            },
            listen(flag){
                this.size = flag.visibility;
                if(flag.visibility == 'maximized'){
                    document.getElementById("chat-widget-container").classList.add('chat-widget-open');
                    document.getElementById("chat-widget-container").classList.remove('chat-widget-close');
                }else{
                    document.getElementById("chat-widget-container").classList.add('chat-widget-close');
                    document.getElementById("chat-widget-container").classList.remove('chat-widget-open');
                }
            },
            initContact(){
                if(this.license){
                    this.$nextTick(() => {
                        const chatImage = document.querySelector('.livechat-widget img');
                        if (chatImage) {
                            chatImage.src = 'path_to_your_custom_image.jpg'; // 替换为你自己的图片路径
                        }
                    });
                }
            },
        },
        mounted() {
            EventBus.$on('maxSize', () => {
                this.size = "maximized";
            });
            EventBus.$on('minSize', () => {
                this.size = "minimized";
            });
            // 当组件挂载后，修改 LiveChatWidget 中的图片
            this.initContact();
        },
    }
</script>

<style>
    .contact{
        background: linear-gradient(to right, var(--blue9), var(--blue10));
        width:5rem;
        height:5rem;
        position:fixed;
        z-index:21;
        right:2rem;
        bottom:10rem;
        border-radius:50%;
        display:flex;
        align-items: center;
        justify-content: center;
        box-shadow:2px 2px .5rem var(--black);
    }
    .contact>svg{
        height:2.5rem;
        width:2.5rem;
    }
    .contact>svg>path{
        fill:#fff;
    }
</style>
