<template>
    <div class="installAPPLoadingBox">
        <div class="downloadHeader">
            <div class="downloadHeaderLeft">
                <img :src="logo" />
                <span>{{ $t("InstallModalTitle") }}</span>
            </div>
        </div>
        <div class="downloadProcessBox">
            <div class="downloadProcessBar" :style="'width:' + process + '%'">
                <span class="downloadProcessValue" v-text="process + '%'"></span>
            </div>
        </div>
    </div>
</template>

<script lang="js">

    import { mapState } from 'vuex';

    export default {
        name:"InstallAPPLoading",
        data(){
            return {
                process:0,
                interVal:null,
            }
        },
        props:{
            visible:{
                type:Boolean,
                default:false,
            }
        },
        watch:{
            visible(newVal){
                if(newVal){
                    this.startAnimate();
                }else{
                    this.process = 0;
                }
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                logo:state => state.user.logo,
            })
        },
        methods:{
            startAnimate(){
                this.interVal = setInterval(() => {
                    if(this.process == 100){
                        clearInterval(this.interVal);
                        setTimeout(() => {
                            this.$emit("update:visible",false);
                        },1000)
                    }else{
                        this.process += 1
                    }
                },30)
            },
        },
    }

</script>

<style lang="css" scoped>

    @keyframes process-anim{
        from {
            background-position: 40px 0;
        }
        to{
            background-position: 0 0;
        }
    }

    .downloadProcessValue{
        font-size:1.1rem;
        font-weight:bold;
        color:var(--basic);
        margin-right:1rem;
    }

    .downloadProcessBar{
        width:0;
        height:100%;
        display:flex;
        justify-content: flex-end;
        align-items: center;
        background-color: var(--gdient2);
        background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
        background-size:2rem 2rem;
        transition:width .2s ease;
        border-radius: 1.5rem;
        animation: process-anim .2s linear infinite;
    }

    .downloadProcessBox{
        width:60%;
        height:2.5rem;
        background-color: #fff;
        border-radius: 1.5rem;
        overflow:hidden;
        margin-top:2.5rem;
    }

    .downloadHeaderLeft > img {
        height:4rem;
    }

    .downloadHeaderLeft > span {
        font-weight:bold;
        color:var(--basic);
        font-size:1.5rem;
        margin-left:0.3rem;
    }

    .downloadHeaderLeft{
        display:flex;
        align-items: center;
    }

    .downloadHeader{
        width:100%;
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .installAPPLoadingBox{
        position:relative;
        margin:0 auto;
        width:90%;
        height:fit-content;
        /* background:#fff; */
        background:var(--primary);
        border-radius:1rem;
        box-sizing:border-box;
        padding:2rem;
        display:flex;
        align-items: center;
        flex-direction: column;
    }

</style>