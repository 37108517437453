<template>
    <div :class="type == 'disabled' || type == 'disabledi' ? 'settingInput disabled' : 'settingInput'" @click="click">
        <slot name="icon"></slot>
        <input type="text" :value="value" :readonly="type == 'normal' || type == 'disabled'"  :placeholder="placeholder" @input="inputvalidate($event)"  />
        <slot name="right"></slot>
    </div>
</template>

<script>
    export default {
        name:"SettingInput",
        props:{
            value:{
                type:String
            },
            placeholder:{
                type:String
            },
            type:{
                type:String,
                default:"normal"
            },
            click:{
                type:Function,
                default:() => {}
            },
            inputvalidate:{
                type:Function,
                default:() => {}
            },
        },  
        data(){
            return {

            }
        },
        methods:{
            
        }
    }
</script>

<style scoped>
    .settingInput.disabled{
        background:var(--subbackground);
    }
    .settingInput.disabled > input::placeholder {
        color: var(--basic) !important;
    }
    .settingInput{
        display:flex;
        align-items: center;
        height:4.5rem;
        width:100%;
        border: 0.1rem solid var(--subbackgroundspecial);
        border-radius:0.5rem;
        box-sizing:border-box;
        padding:0 1rem;
        overflow:hidden;
    }
    .settingInput > input {
        border:none;
        outline:none;
        background:none;
        padding:0;
        margin:0;
        color:var(--basic);
        font-size:1.3rem;
        font-weight:bold;
        flex:1;
        width:100%;
    }
</style>