<template>
    <el-dropdown
        style="width: 100%"
        @visible-change="showChange"
        trigger="click" 
        placement="bottom-start"
        class="dropOutside"
        v-if="visible"
    >
        <div class="mineSelect">
            <span v-text="list[activeType]?.label || $t('Select')"></span>
            <svg
                  :style="
                    selectOpen
                      ? 'transform:rotate(-90deg)'
                      : 'transform:rotate(90deg)'
                  "
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                >
                  <path
                    d="M236.552013 926.853955a55.805997 55.805997 0 0 0 0 80.454996 59.682997 59.682997 0 0 0 82.794996 0l468.099978-455.081978a55.805997 55.805997 0 0 0 0-80.453996L319.348009 16.689999a59.682997 59.682997 0 0 0-82.794996 0 55.805997 55.805997 0 0 0 0 80.454996L663.401993 511.999975 236.624013 926.853955z"
                    fill="#8da5ca"
                  ></path>
            </svg>
        </div>
        <el-dropdown-menu slot="dropdown" class="mine-dropdown-menu">
            <div
                :class="activeType == index ? 'mineItem active' : 'mineItem'"

                @click="change(item,index)"
                v-for="(item, index) in list"
                :key="index"
            >
                <div class="mineLeft">
                    <span v-text="item.label"></span>
                </div>
            </div>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>

    export default {
        name:"Select",
        props:{
            change:{
                type:Function,
                default:() => {}
            },
            visible:{
                type:Boolean,
                dafault:false,
            },
            list:{
                type:Array,
                default:[],
            },
            activeType:{
                type:Number,
            }
        },  
        data(){
            return {
                selectOpen:false,
            }
        },
        methods:{
            showChange(val){
                this.selectOpen = val;
            }
        }
    }

</script>

<style lang="css" scoped>

    .mineItem.active span {
        color:var(--basic) !important;
    }

    .mineItem.active{
        background:var(--subbackground) !important;
    }

    .mine-dropdown-menu{
        left:26.2rem !important;
    }

    .dropOutside{
        margin-right:1rem;
        width:fit-content !important;
    }

    .mineLeft {
        display: flex;
        align-items: center;
    }
    .mineLeft > span {
        font-weight: bold;
        font-size: 1.2em;
        color: var(--subfont);
    }

    .mineItem {
        height: fit-content;
        box-sizing: border-box;
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 10rem;
    }

    .mineSelect path {
        fill:var(--subfont);
    }

    .mineSelect > svg {
        height: 1.2rem;
        width: 1.2rem;
    }

    .mineSelect > span {
        font-size:1.2rem;
        font-weight:bold;
    }

    .mineSelect {
        height: 3.5rem;
        border: 1px solid var(--subfont);
        width: 10rem;
        border-radius: 1rem;
        box-sizing: border-box;
        padding: 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>