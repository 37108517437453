<template>
    <div class="container">
    </div>
</template>

<script>
    export default {
        name:"Skeleton",
        data(){
            return {

            }
        },
        methods:{

        }
    }
</script>

<style scoped>
    .container{
        width:100%;
        height:100%;
        background:var(--primary);
    }
</style>