<template>
    <div class="childBox" style="color:#fff">
        <div class="searchBox">
            <DatePicker :startDate.sync="searchInfo.createTime[0]" :endDate.sync="searchInfo.createTime[1]"></DatePicker>
            <div class="searchButton buttonTransition">
                {{ $t("Search") }}
            </div>
        </div>
        <div class="headerBox">
            <div @click="changeAction(index)" :class="item.active ? 'headerItem buttonTransition active' : 'headerItem buttonTransition'" v-for="(item,index) in headerList" :key="index">
                <span v-text="item.name"></span>
            </div>
        </div>
        <div class="columnList">
            <div class="columnItem">
                <span class="columnLabel">{{ $t("SubCommission") }}</span>
                <span class="columnValue">{{ data.directSubordinatesCommission }}</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">{{ $t("TeamCommission") }}</span>
                <span class="columnValue">{{ data.indirectSubordinatesCommission }}</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">{{ $t("TotalCommission") }}</span>
                <span class="columnValue">{{ data.allCommission }}</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">{{ $t("NewDirectSub") }}</span>
                <span class="columnValue">{{ data.directSubordinatesCount }}</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">{{ $t("NewTeamSub") }}</span>
                <span class="columnValue">{{ data.indirectSubordinatesCommission }}</span>
            </div>
            <div class="columnItem">
                <span class="columnLabel">{{ $t("TotalNumberOfPeople") }}</span>
                <span class="columnValue">{{ data.allCount }}</span>
            </div>
        </div>
        <div class="description">{{ $t("ReferRefTips") }}</div>
    </div>
</template>

<script>

    import DatePicker from "../components/DatePicker.vue";
    import { GetCommissionByChild } from "@/api/proxy.js";

    export default {
        name:"ReferRef",
        data(){
            return {
                headerList:[
                    {
                        name:this.$t("All"),
                        active:true,
                    },
                    {
                        name:this.$t("Today"),
                        active:false,
                    },
                    {
                        name:this.$t("Yesterday"),
                        active:false,
                    },
                    {
                        name:this.$t("ThisWeek"),
                        active:false,
                    },
                    {
                        name:this.$t("ThisMonth"),
                        active:false,
                    },
                ],
                searchInfo:{
                    createTime:["",""]
                },
                data:{
                    
                }
            }
        },
        mounted(){
            this.init();
        },
        components:{
            DatePicker
        },
        methods:{
            init(){
                GetCommissionByChild().then(res => {
                    if(res.code == 200){
                        this.data = res.data;
                    }else{
                        this.$message(res.msg);
                    }
                })
            },
            changeAction(index){
                this.headerList.forEach((item, i) => {
                    if (i === index) {
                        item.active = true;
                    } else {
                        item.active = false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .searchButton{
        background:var(--blue);
        color:#fff;
        border-radius:1rem;
        box-sizing:border-box;
        padding:0 2em;
        height:3.5rem;
        font-size:1.3rem;
        display:flex;
        align-items: center;
        margin-left:1rem;
    }
    .searchBox{
        margin-top:2rem;
        display:flex;
    }
    .myRange{
        width:25rem !important;
        height:3.5rem !important;
    }

    .description{
        color:var(--subfont);
        text-align: center;
        font-size:1.3rem;
        margin-top:1rem;
    }
    .columnLabel{
        color:var(--basic);
        font-size:1.5rem;
        font-weight:bold;
    }
    .columnValue{
        color:#fff;
        font-size:1.5rem;
    }
    .columnItem{
        background:var(--primary);
        display:flex;
        justify-content: space-between;
        align-items: center;
        box-sizing:border-box;
        padding:1.5rem 3rem;
        border-radius:1rem;
        margin-top:1rem;
    }
    .columnList{
        display:flex;
        flex-direction: column;
    }

    .headerItem.active{
        background:var(--gdient2);
    }
    .headerItem.active span{
        color:#fff !important;
    }
    .headerItem span{
        color:var(--subfont);
        font-size:1.3rem;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:bold;
    }
    .headerItem{
        height:4rem;
        width:8rem;
        background:var(--subbackground);
        border-radius:1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        box-sizing:border-box;
        padding:0 1rem;
        text-align: center;
    }
    .headerBox{
        display:flex;
        margin-top:2rem;
        justify-content: space-between;
    }
    .childBox{
        height:auto;
        overflow:hidden;
        width:100%;
    }
</style>