<template>
    <div>
        <slot></slot>
        <div :class="nopadding ? 'fixed-nav-no-padding' : 'fixed-nav'" v-show="scrollTop"
            :style="{ top: downloadVisible ? '14rem' : '6rem' }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {
        name:"ClassList",
        data(){
            return {
                scrollTop:false,
            }
        },
        computed:{
            ...mapState({
                downloadVisible:state => state.user.appEnable,
            }),
        },
        props:{
            nopadding:{
                type:Boolean,
                default:false,
            }
        },
        watch:{
        },
        methods:{
            changeScrollTop(flag){
                this.scrollTop = flag;
            }
        },
        mounted(){
        },
    }

</script>

<style>
    .fixed-nav .class-item-box{
        background: var(--subbackground);
    }
</style>

<style scoped>
    .fixed-nav{
        position:absolute;
        top:6rem;
        left:0;
        z-index:30;
        box-sizing:border-box;
        padding:0 2rem;
        width:100%;
        overflow:hidden;
        background: var(--primary);
    }
    .fixed-nav-no-padding{
        position:absolute;
        top:6rem;
        left:0;
        z-index:30;
        box-sizing:border-box;
        width:100%;
        overflow:hidden;
        margin-top:1rem;
    }
</style>