<template>
    <div @click="toClick" :class="current == index ? 'buttonTransition vipListItem active' : 'buttonTransition vipListItem'">
        <img :style="level <= user.level ? 'filter:none !important;' : ''" :src="icon" />
        <span v-text="name"></span>
    </div>
</template>

<script lang="js">

    export default {
        name:"VIPItem",
        props:{
            current:{
                type:Number
            },
            index:{
                type:Number
            },
            name:{
                type:String,
            },
            icon:{
                type:String
            },
            level:{
                type:Number
            },  
            user:{
                type:Object
            },

        },  
        data(){
            return {

            }
        },
        methods:{
            toClick(){
                this.$emit("click",this.index)
            }
        }
    }

</script>

<style lang="css" scoped>

    .vipListItem > span {
        color:var(--basic);
        font-size:1.5rem;
        font-weight:bold;
        margin-top:0.5rem;
    }

    .vipListItem > img {
        width:70%;
        height:5rem !important;
        filter: grayscale(100%);
    }

    .vipListItem.active{
        background:var(--gdient2) !important;
    }

    .vipListItem:last-child{
        margin-right:0;
    }

    .vipListItem{
        background-color:var(--primary);
        width:7.1rem;
        height:10rem;
        border-radius:1rem;
        margin-right:1.3rem;
        box-sizing:border-box;
        padding:1rem 0;
        display:inline-flex;
        flex-direction: column;
        align-items: center;
        overflow:hidden;
    }

</style>