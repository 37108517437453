export function getHashParam(param) {
    const hash = window.location.hash;
    const hashParams = new URLSearchParams(hash.split('?')[1]);
    return hashParams.get(param);
}

export function maskString(input) {
    if (!input) {
        return "";  // 如果传入的是 null，返回空字符串
    }
    if (input.length <= 4) {
        // 如果字符串长度小于或等于4，返回原字符串
        return input;
    }
    return input;
}