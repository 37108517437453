<template>
    <div class="game-item">
        <img :src="item.fullCategoryImg" v-show="!loading" @click="toClassification"/>
        <div class="skeleton" v-show="loading">
            <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                <path d="M298.666667 298.666667h426.666666c140.8 0 256 115.2 256 256s-115.2 256-256 256c-72.533333 0-140.8-29.866667-192-85.333334h-46.933333c-93.866667 106.666667-256 115.2-362.666667 21.333334s-115.2-256-21.333333-362.666667C157.866667 328.533333 226.133333 298.666667 298.666667 298.666667m-42.666667 128v85.333333H170.666667v85.333333h85.333333v85.333334h85.333333v-85.333334h85.333334v-85.333333H341.333333v-85.333333H256m405.333333 128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64m128-128c-34.133333 0-64 29.866667-64 64s29.866667 64 64 64 64-29.866667 64-64-29.866667-64-64-64z" fill="#252e3a">
                </path>
            </svg>
        </div>
    </div>
</template>

<script>

export default {
    name: "CategoryItem",
    props: {
        item: {
            // 目录对象
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 200);
    },
    created() {

    },
    methods: {
        toClassification() {
            this.$router.push("/classification?categoryId=" + this.item.categoryId)
        },
    }
}

</script>

<style scoped>
.skeleton > svg {
    width: 50%;
    height: 50%;
}

.skeleton {
    width: 100%;
    height: 100%;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.classItemCollection svg {
    width: 1.6rem;
    height: 1.6rem;
}

.classItemCollection {
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    z-index: 20;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.collectioned path {
    fill: #e4d425;
}

.uncollection path {
    fill: rgba(255, 255, 255, .7)
}

.game-item {
    height: 18rem;
    width: calc(33.33% - 1rem);
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
}

.game-item:nth-child(3n) {
    margin-right: 0;
}

.game-item img {
    width: 100%;
    height: 100%;
}
</style>
