<template>
    <div class="viewBoxGlobal">
        <div class="box">
            <div class="settingTitle">
                <div style="display:flex;align-items: center;">
                    <div class="goBack" @click="goBack">
                        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path d="M947.4 864C893.2 697.7 736.2 578.9 551 575.5c-23.1-0.4-44.9 0.1-65.6 1.5v164.3c0.1 0.5 0.2 1 0.2 1.5 0 4-3.3 7.3-7.3 7.3-2.7 0-5-1.4-6.2-3.5v0.7L68.8 465.4h2.1c-4 0-7.3-3.3-7.3-7.3 0-2.9 1.7-5.4 4.1-6.6L472 169v0.7c1.3-2.1 3.6-3.5 6.2-3.5 4 0 7.3 3.3 7.3 7.3 0 0.5-0.1 1-0.2 1.5v159.4c18.5-0.9 37.9-1.2 58.3-0.8 230.1 3.9 416.7 196.9 416.7 427.1 0.1 35.5-4.5 70.2-12.9 103.3z m-462-704.4v0.2h-0.4l0.4-0.2z m0 596.9l-0.3-0.2h0.3v0.2z" fill=""></path>
                        </svg>
                    </div>
                    <span>{{ $t("AccountSetting") }}</span>
                </div>
            </div>
            <div class="phoneNumberBox">
                <div class="phoneNumberBoxFirst">
                    <div class="FirstLeft">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M761.503 2.906H242.122c-32.405 0-58.932 26.06-58.932 58.528v902.302c0 32.157 26.217 58.217 58.932 58.217h519.381c32.345 0 58.591-26.06 58.591-58.217V61.123c0-32.156-26.246-58.217-58.591-58.217M452.879 61.123h98.147c6.78 0 12.316 5.536 12.316 12.253 0 6.749-5.535 12.253-12.316 12.253H452.88c-6.78 0-12.345-5.504-12.345-12.253-0.001-6.717 5.566-12.253 12.345-12.253m48.763 919.47c-29.637 0-53.988-23.946-53.988-53.677 0-29.357 24.04-53.614 53.988-53.614 29.917 0 53.987 23.884 53.987 53.614 0 29.731-24.069 53.677-53.987 53.677m264.715-177.45c0 16.234-13.187 29.108-29.234 29.108H266.504c-16.357 0-29.326-13.186-29.326-29.108V163.5c0-16.233 13.28-29.139 29.326-29.139H736.75c16.421 0 29.358 13.185 29.358 29.139v639.642h0.249z m0 0"
                                fill="#979797">
                            </path>
                        </svg>
                        <span class="FirstTitle">{{ $t("MobileNumber") }}</span>
                    </div>
                    <span>
                        ✔{{ $t("Verified") }}
                    </span>
                </div>
                <div class="phoneNumberBoxSecond">
                    Set up a mobile numbe rto access your account anytime and use it to log in from any device
                </div>
                <div class="phoneNumberBoxThird">
                    +63<span style="margin-left:1rem">9367783752</span>
                </div>
            </div>
            <div class="phoneNumberBox">
                <div class="phoneNumberBoxFirst">
                    <div class="FirstLeft">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M868.935 1008.63l-713.868 0c-40.657 0-72.291-31.631-72.291-67.773l0-496.994c0-36.144 31.631-67.773 72.291-67.773l713.868 0c40.657 0 72.291 31.631 72.291 67.773l0 496.994c0 36.144-31.631 67.773-72.291 67.773l0 0 0 0 0 0zM512 543.259c-58.732 0-108.432 45.187-108.432 99.402 0 36.144 22.586 67.773 54.218 85.849l0 94.887c0 27.108 22.586 49.696 54.218 49.696s54.218-22.586 54.218-49.696l0-94.887c31.631-18.071 54.218-49.696 54.218-85.849 0-54.218-49.696-99.402-108.432-99.402l0 0 0 0zM512 114.031c-117.471 0-216.867 90.356-216.867 198.797l-108.432 0c0-162.655 144.582-298.202 320.79-298.202s320.79 135.546 320.79 298.202l-108.432 0c9.041-112.951-90.356-198.797-207.836-198.797l0 0 0 0zM512 114.031z"
                                fill="#272636">
                            </path>
                        </svg>
                        <span class="FirstTitle">{{ $t("Password") }}</span>
                    </div>
                </div>
                <div class="phoneNumberBoxSecond">
                    The length of the entered password must be 6-16 digits both numbers and letters are acceptable
                </div>
                <div class="phoneNumberBoxButton buttonTransition" @click="showUserPasswordSet">
                    {{ $t("Change") }}
                </div>
            </div>
            <div class="phoneNumberBox">
                <div class="phoneNumberBoxFirst">
                    <div class="FirstLeft">
                        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M868.935 1008.63l-713.868 0c-40.657 0-72.291-31.631-72.291-67.773l0-496.994c0-36.144 31.631-67.773 72.291-67.773l713.868 0c40.657 0 72.291 31.631 72.291 67.773l0 496.994c0 36.144-31.631 67.773-72.291 67.773l0 0 0 0 0 0zM512 543.259c-58.732 0-108.432 45.187-108.432 99.402 0 36.144 22.586 67.773 54.218 85.849l0 94.887c0 27.108 22.586 49.696 54.218 49.696s54.218-22.586 54.218-49.696l0-94.887c31.631-18.071 54.218-49.696 54.218-85.849 0-54.218-49.696-99.402-108.432-99.402l0 0 0 0zM512 114.031c-117.471 0-216.867 90.356-216.867 198.797l-108.432 0c0-162.655 144.582-298.202 320.79-298.202s320.79 135.546 320.79 298.202l-108.432 0c9.041-112.951-90.356-198.797-207.836-198.797l0 0 0 0zM512 114.031z"
                                fill="#272636">
                            </path>
                        </svg>
                        <span class="FirstTitle">{{ $t("WithdrawPassword") }}</span>
                    </div>
                </div>
                <div class="phoneNumberBoxSecond">
                    The length of the entered password must be 6-16 digits both numbers and letters are acceptable
                </div>
                <div class="phoneNumberBoxButton buttonTransition" @click="showBusinessPasswordSet">
                    {{ $t("Change") }}
                </div>
            </div>
        </div>
        <Footer></Footer>
        <Modal
            :visible.sync="setVisible"
            :title="$t('ChangePassword')"
        >
            <div class="passwordBox">
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="current" :type="currentPwd" :placeholder="$t('CurrentPassword')"/>
                    </div>
                    <template v-if="currentPwd == 'password'">
                        <svg @click="currentPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="currentPwd == 'text'">
                        <svg @click="currentPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="newp" :type="newPwd" :placeholder="$t('NewPassword')"/>
                    </div>
                    <template v-if="newPwd == 'password'">
                        <svg @click="newPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="newPwd == 'text'">
                        <svg @click="newPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="confirmP" :type="contirmPwd" :placeholder="$t('ConfirmNewPassword')"/>
                    </div>
                    <template v-if="contirmPwd == 'password'">
                        <svg @click="contirmPwd = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="contirmPwd == 'text'">
                        <svg @click="contirmPwd = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="queryChangeButton buttonTransition" @click="confirmUserPassword">
                    {{ $t("ConfirmChange") }}
                </div>
            </div>
        </Modal>
        <Modal
            :visible.sync="bsetVisible"
            :title="$t('ChangeTransactionPassword')"
        >
            <div class="passwordBox">
                <div class="InputBox" v-if="!isFirst">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="currentb" :type="currentbt" :placeholder="$t('CurrentPassword')"/>
                    </div>
                    <template v-if="currentbt == 'password'">
                        <svg @click="currentbt = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="currentbt == 'text'">
                        <svg @click="currentbt = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="newbp" :type="newbpt" :placeholder="$t('NewPassword')"/>
                    </div>
                    <template v-if="newbpt == 'password'">
                        <svg @click="newbpt = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="newbpt == 'text'">
                        <svg @click="newbpt = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="InputBox">
                    <div style="display:flex;align-items: center;">
                        <div class="selectBox">
                            <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                                <path
                                    d="M817.845757 344.015078l-55.874565 0L761.971192 288.135396c0-124.046201-116.653837-223.491097-251.434519-223.491097-134.774543 0-251.42838 99.444896-251.42838 223.491097l0 55.879682-55.874565 0c-59.69559 0-111.754247 56.901964-111.754247 111.748107l0 391.125025c0 54.946426 52.170197 111.74299 111.754247 111.74299l614.611006 0c59.690474 0 111.754247-56.908104 111.754247-111.74299L929.59898 455.764208C929.600003 348.239286 817.845757 344.015078 817.845757 344.015078zM314.977741 288.135396c0-98.556667 87.761809-167.611415 195.559954-167.611415 107.809401 0 195.565071 69.054749 195.565071 167.611415l0 55.879682L314.977741 344.015078 314.977741 288.135396zM873.725438 846.889233c0 19.48375-34.821063 55.868425-55.879682 55.868425L203.234751 902.757658c-21.171182 0-55.874565-36.485983-55.874565-55.868425L147.360186 455.764208c0-19.48375 34.809807-55.874565 55.874565-55.874565l614.611006 0c21.171182 0 55.879682 36.491099 55.879682 55.874565L873.725438 846.889233z"
                                    fill="#495669"></path>
                                <path d="M510.537695 511.63775c46.291302 0 83.811848 37.524638 83.811848 83.810824 0 46.291302-37.519522 83.810824-83.811848 83.810824-46.286186 0-83.805708-37.519522-83.805708-83.810824C426.731988 549.163411 464.251509 511.63775 510.537695 511.63775z" fill="#495669"></path>
                                <path d="M482.605529 679.260421l55.868425 0 0 111.748107L482.605529 791.008528 482.605529 679.260421z" fill="#495669"></path>
                            </svg>
                        </div>
                        <input v-model="confirmbp" :type="confirmbpt" :placeholder="$t('ConfirmNewPassword')"/>
                    </div>
                    <template v-if="confirmbpt == 'password'">
                        <svg @click="confirmbpt = 'text'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M512 298.666667c-162.133333 0-285.866667 68.266667-375.466667 213.333333 89.6 145.066667 213.333333 213.333333 375.466667 213.333333s285.866667-68.266667 375.466667-213.333333c-89.6-145.066667-213.333333-213.333333-375.466667-213.333333z m0 469.333333c-183.466667 0-328.533333-85.333333-426.666667-256 98.133333-170.666667 243.2-256 426.666667-256s328.533333 85.333333 426.666667 256c-98.133333 170.666667-243.2 256-426.666667 256z m0-170.666667c46.933333 0 85.333333-38.4 85.333333-85.333333s-38.4-85.333333-85.333333-85.333333-85.333333 38.4-85.333333 85.333333 38.4 85.333333 85.333333 85.333333z m0 42.666667c-72.533333 0-128-55.466667-128-128s55.466667-128 128-128 128 55.466667 128 128-55.466667 128-128 128z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                    <template v-if="confirmbpt == 'text'">
                        <svg @click="confirmbpt = 'password'" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                            <path
                                d="M332.8 729.6l34.133333-34.133333c42.666667 12.8 93.866667 21.333333 145.066667 21.333333 162.133333 0 285.866667-68.266667 375.466667-213.333333-46.933333-72.533333-102.4-128-166.4-162.133334l29.866666-29.866666c72.533333 42.666667 132.266667 106.666667 183.466667 192-98.133333 170.666667-243.2 256-426.666667 256-59.733333 4.266667-119.466667-8.533333-174.933333-29.866667z m-115.2-64c-51.2-38.4-93.866667-93.866667-132.266667-157.866667 98.133333-170.666667 243.2-256 426.666667-256 38.4 0 76.8 4.266667 110.933333 12.8l-34.133333 34.133334c-25.6-4.266667-46.933333-4.266667-76.8-4.266667-162.133333 0-285.866667 68.266667-375.466667 213.333333 34.133333 51.2 72.533333 93.866667 115.2 128l-34.133333 29.866667z m230.4-46.933333l29.866667-29.866667c8.533333 4.266667 21.333333 4.266667 29.866666 4.266667 46.933333 0 85.333333-38.4 85.333334-85.333334 0-12.8 0-21.333333-4.266667-29.866666l29.866667-29.866667c12.8 17.066667 17.066667 38.4 17.066666 64 0 72.533333-55.466667 128-128 128-17.066667-4.266667-38.4-12.8-59.733333-21.333333zM384 499.2c4.266667-68.266667 55.466667-119.466667 123.733333-123.733333 0 4.266667-123.733333 123.733333-123.733333 123.733333zM733.866667 213.333333l29.866666 29.866667-512 512-34.133333-29.866667L733.866667 213.333333z"
                                fill="#495669"></path>
                        </svg>
                    </template>
                </div>
                <div class="queryChangeButton buttonTransition" @click="confirmTranscationPassword">
                    {{ $t("ConfirmChange") }}
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>

import Footer from "../components/Footer.vue";
import Modal from "../components/Modal.vue";
import TableSimple from "@/components/TableSimple.vue";
import { updatePassword } from "@/api/user";
import { GetTranscationInfo,SetTranscationPassword,ChangeTranscationPassword } from "@/api/deposit";

export default {
    name: "Settings",
    data() {
        return {
            acoountType:[], // 账户类型列表
            isFirst:false,

            // 以下是用户密码的设置form
            setVisible: false,
            currentPwd: "password",
            newPwd: "password",
            contirmPwd: "password",
            current: '', // 当前密码
            newp: "", // 新密码
            confirmP: "", // 新密码确认密码

            // 以下是交易密码的设置form
            bsetVisible:false,
            isFirst:"", // 是否是第一次设置交易密码
            currentb:"", // 当前交易密码（如果是首次设置就不显示这个）
            newbp:"", // 新交易密码
            confirmbp:"", // 新交易密码确认密码
            currentbt:"password", // 当前交易密码的input类型
            newbpt:"password", // 同理
            confirmbpt:"password", // 同理
        }
    },
    components: {
        Footer,
        Modal,
        TableSimple,
    },
    methods: {
        confirmUserPassword() { // 确认提交用户密码
            if (!this.current || !this.newp || !this.confirmP) {
                this.$message(this.$t("PasswordInputEmpty"));
                return;
            }
            if (this.newp !== this.confirmP) {
                this.$message(this.$t("PasswordDontMatch"));
                return;
            }

            const pwd = {oldPassword: this.current, newPassword: this.newp};

            updatePassword(pwd).then(res => {
                if (res.success) {
                    this.$message(res.msg);
                    this.setVisible = false;
                } else {
                    this.$message(res.msg);
                }
            });
        },
        confirmTranscationPassword(){ // 确认提交交易密码
            let data;
            if(this.isFirst){ // 第一次设置交易密码
                if (this.newbp !== this.confirmbp) {
                    this.$message(this.$t("PasswordDontMatch"));
                    return;
                }
                data = {
                    newPassword:this.newbp
                }
                SetTranscationPassword(data).then(res => {
                    if(res.code == 200){
                        this.isFirst = false;
                    }
                    this.$message(res.msg);
                })
            }else{
                if (!this.currentb || !this.newbp || !this.confirmbp) {
                    this.$message(this.$t("PasswordInputEmpty"));
                    return;
                }
                if (this.newbp !== this.confirmbp) {
                    this.$message(this.$t("PasswordDontMatch"));
                    return;
                }
                data = {
                    oldPassword:this.currentb,
                    newPassword:this.newbp
                }
                ChangeTranscationPassword(data).then(res => {
                    this.$message(res.msg);
                })
            }
        },
        showUserPasswordSet() { // 显示设置用户密码弹窗
            this.setVisible = true;
        },
        showBusinessPasswordSet(){ // 显示设置交易密码弹窗
            this.bsetVisible = true;
        },
        checkTranscationStatus(){ // 检查交易状态
            GetTranscationInfo().then(res => {
                if(res.code == 200){
                    this.isFirst = !res.data.hasSetTransactionPassword;
                }
            })
        },
        goBack(){ // 返回页面
            this.$router.go(-1)
        }
    },
    created(){
        this.checkTranscationStatus();
    }
}

</script>

<style scoped>

.queryChangeButton {
    background: var(--gdient2);
    color: #fff;
    font-size: 1.7rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin-top: 3rem;
    font-weight: bold;
}

.passwordBox {
    box-sizing: border-box;
    padding-top: 3rem;
}

.selectBox svg {
    height: 3rem;
    width: 2.5rem;
}

.selectBox span {
    color: var(--subfont);
    margin-left: .5rem;
    font-size: 1.5rem;
}

.selectBox {
    display: flex;
    align-items: center;
}

.selectBox path {
    fill:var(--subfont);
}

.InputBox {
    height: 5.5rem;
    background: var(--subbackground);
    width: 100%;
    border-radius: 1rem;
    display: flex;
    box-sizing: border-box;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.InputBox > div {
    width:100%;
}

.InputBox input::placeholder {
    color: var(--subfont);
    font-weight: bold;
}

.InputBox input {
    color: #fff;
    font-size: 1.5rem;
    background: none;
    outline: none;
    border: none;
    margin-left: 1rem;
    flex:1;
}

.InputBox > svg {
    height: 2rem;
    width: 2rem;
}

.InputBox path {
    fill:var(--subfont);
}

</style>

<style scoped>
.phoneNumberBoxButton {
    background: var(--green2);
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 1rem;
    margin-top: 2rem;
    font-size: 1.7rem;
    font-weight: bold;
}

.phoneNumberBoxThird {
    border: 1px solid var(--disabled3);
    height: 4.5rem;
    width: 95%;
    box-sizing: border-box;
    padding: 0 2rem;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 1rem;
    margin-top: 3rem;
}

.phoneNumberBoxSecond {
    color: var(--mainfont);
    font-weight: bold;
    font-size: 1.4rem;
    width: 95%;
    margin: 0 auto;
    word-break: break-all;
    margin-top: 1.5rem;
}

.FirstTitle {
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    margin-left: 1rem;
}

.FirstLeft path {
    fill: var(--basic)
}

.FirstLeft > svg {
    height: 3rem;
    width: 3rem;
}

.FirstLeft {
    display: flex;
    align-items: center;
}

.phoneNumberBoxFirst > span {
    color: var(--green3);
    font-size: 1.5rem;
}

.phoneNumberBoxFirst {
    display: flex;
    justify-content: space-between;
}

.phoneNumberBox {
    box-sizing: border-box;
    padding: 2rem 3rem;
    background: var(--primary);
    margin-top: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
}

.box {
    background: var(--background);
    box-sizing: border-box;
    padding-top: 8rem;
    padding-bottom: 8rem;
    box-sizing: border-box;
    padding: 2rem 2rem;
}

.settingTitle span {
    color: #fff;
    font-weight: bold;
    font-size: 2.5rem;
    margin-left: 2rem;
}

.settingTitle {
    display: flex;
    margin-top: 3rem;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
}

.goBack {
    width: 5rem;
    height: 4rem;
    background: var(--primary);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.goBack > svg {
    height: 2.5rem;
    width: 3rem;
}

.goBack > svg > path {
    fill: var(--basic);
}
</style>
