<template>
    <div class="otherBox" style="padding-top:0;">
        <div class="box">
            <div class="floating" @click="toBack">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path d="M947.4 864C893.2 697.7 736.2 578.9 551 575.5c-23.1-0.4-44.9 0.1-65.6 1.5v164.3c0.1 0.5 0.2 1 0.2 1.5 0 4-3.3 7.3-7.3 7.3-2.7 0-5-1.4-6.2-3.5v0.7L68.8 465.4h2.1c-4 0-7.3-3.3-7.3-7.3 0-2.9 1.7-5.4 4.1-6.6L472 169v0.7c1.3-2.1 3.6-3.5 6.2-3.5 4 0 7.3 3.3 7.3 7.3 0 0.5-0.1 1-0.2 1.5v159.4c18.5-0.9 37.9-1.2 58.3-0.8 230.1 3.9 416.7 196.9 416.7 427.1 0.1 35.5-4.5 70.2-12.9 103.3z m-462-704.4v0.2h-0.4l0.4-0.2z m0 596.9l-0.3-0.2h0.3v0.2z" fill=""></path>
                </svg>
            </div>
            <div class="floating" style="top:7rem" @click="toDeposit">
                <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
                    <path d="M956.774 366.54v-83.455c0-62.106-50.33-112.435-112.435-112.435H182.528c-62.106 0-112.435 50.33-112.435 112.435v83.456h886.681zM70.093 444.11v319.846c0 62.106 50.33 112.435 112.435 112.435h661.76c62.106 0 112.435-50.33 112.435-112.435V444.11H70.093z m730.675 284.928H632.013l54.016-120.474h168.755l-54.016 120.474z" fill="#ffffff">

                    </path>
                    <path d="M922.214 315.392c0-42.035-3.993-83.098-11.57-122.931-18.638-13.67-41.524-21.811-66.356-21.811h-661.76c-62.106 0-112.435 50.33-112.435 112.435v83.456h850.176a675.814 675.814 0 0 0 1.945-51.149zM70.093 444.109v319.846c0 62.106 50.33 112.435 112.435 112.435h422.4c64.82-39.065 122.368-88.985 169.984-147.353h-142.9l54.017-120.474h166.963a650.052 650.052 0 0 0 56.525-164.454H70.093z" fill="#ffffff">

                    </path>
                    <path d="M737.382 170.65H182.528c-62.106 0-112.435 50.33-112.435 112.435v83.456H678.81c29.184-60.672 49.254-126.567 58.572-195.891zM70.092 737.126c6.247 0.154 12.494 0.308 18.791 0.308 227.943 0 428.595-116.583 545.792-293.274H70.093v292.966z" fill="#ffffff">

                    </path>
                    <path d="M495.565 170.65H182.528c-62.106 0-112.435 50.33-112.435 112.435v83.456h257.792a657.229 657.229 0 0 0 167.68-195.891zM201.933 444.109H70.093v45.363c45.977-10.445 90.06-25.805 131.84-45.363z" fill="#ffffff">

                    </path>
                </svg>
            </div>
            <iframe v-if="iframeSrc" :src="iframeSrc" frameborder="0"></iframe>
            <iframe v-if="iframeContent" :srcdoc="iframeContent" frameborder="0"></iframe>
        </div>

        <Modal :visible.sync="confirmVisible" :gradient="true">
            <span class="confirmDes">{{ $t("OtherModalTips") }}</span>
            <div class="confirmOption">
                <div class="cancelButton" @click="closeConfirm">
                    {{ $t("Cancel") }}
                </div>
                <div class="confirmButton" @click="queryConfirm">
                    {{ $t("OK") }}
                </div>
            </div>
        </Modal>

        <Modal :visible.sync="errorVisible" :gradient="true">
            <div style="display:flex;flex-direction: column;align-items: center;">
                <span class="confirmDes">{{ errorText }}</span>
                <div class="confirmOption">
                    <div class="confirmButton" @click="goHomeBack">
                        {{ $t("OK") }}
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import {createPayOrderApi} from "@/api/user";
import {play} from "@/api/user"
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"

import Modal from "@/components/Modal.vue";

export default {
    name: "Other",
    data() {
        return {
            iframeSrc: '',
            iframeContent: '',

            confirmVisible:false,
            errorVisible:false,
            errorText:"",

            mode:"back",
        }
    },
    components: {
        Header,
        Footer,
        Modal
    },
    methods: {

        goHomeBack(){
            if(this.$route.query.intype == 'home'){
                this.$router.push("home");
            }else{
                this.$router.push({
                    query:{id:this.$route.query.folderid,name:this.$route.query.foldername},
                    path:"/classification"
                });
            }
        },

        queryConfirm() {
            if(this.mode == 'back'){
                // this.$router.go(-1);
                if(this.$route.query.intype == 'home'){
                    this.$router.push("home");
                }else{
                    this.$router.push({
                        query:{id:this.$route.query.folderid,name:this.$route.query.foldername},
                        path:"/classification"
                    });
                }
            }else{
                this.$router.push("/deposit?mode=deposit")
            }
        },
        closeConfirm() {
            this.confirmVisible = false;
        },

        toBack(){
            this.mode = "back";
            this.confirmVisible = true;
        },

        toDeposit(){
            this.mode = "deposit"
            this.confirmVisible = true;
        }

    },
    mounted() {
        let data = this.$route.query.data;
        let type = this.$route.query.type;
        if (type && data) {
            if (type === "game") {
                play(JSON.parse(data)).then(res => {
                    this.errorText = res.msg;
                    if (res.data.type === "html") {
                        this.iframeContent = res.data.url;
                    } else if (res.data.type === "http") {
                        this.iframeSrc = res.data.url;
                    }
                }).catch(err => {
                    this.errorVisible = true;
                })
            } else if (type === "pay") {
                createPayOrderApi(JSON.parse(data)).then(res => {
                    if (res.data.type === "html") {
                        this.iframeContent = res.data.url;
                    } else if (res.data.type === "http") {
                        this.iframeSrc = res.data.url;
                    }
                });
            }
        }
    }
}
</script>

<style scoped>

.confirmButton {
    color: #fff;
    width: 12rem;
    background: var(--success);
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.cancelButton {
    color: #fff;
    width: 12rem;
    background: var(--error);
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.confirmOption {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    box-sizing: border-box;
    padding: 0 3rem;
}

.confirmDes {
    color: var(--mainfont);
    font-size: 1.7rem;
    font-weight: bold;
}

.floating svg{
    width:2rem;
    height:2rem;
}

.floating path{
    fill:white;
}

.floating{
    cursor:pointer;
    position:absolute;
    width:4rem;
    height:4rem;
    background:rgba(0,0,0,0.2);
    border-radius:50%;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index:10;
    left:2rem;
    top:2rem;
}

iframe {
    height: 100%;
    width: 100%;
    border: none !important;
}

.box {
    background: var(--background);
    box-sizing: border-box;
    box-sizing: border-box;
    height:100%;
    width:100%;
}
.otherBox{
    height:100%;
    width:100%;
    scrollbar-width: none;
    -webkit-overflow-scrolling: unset;
    box-sizing: border-box;
}
</style>
