<template>
    <div class="buttonTransition class-item">
        <div class="class-item-box" @click="changeClass(index)" :class="active ? 'active' : ''" v-show="!loading">
            <img :src="item.fullCategoryImg"/>
            <span v-text="categoryName"></span>
        </div>
        <div v-show="loading" class="skeleton">
        </div>
    </div>
</template>

<script>
export default {
    name: "ClassItem",
    props: {
        index: {
            type: Number,
            default: 0,
        },
        item: {
            type: Object,
        },
        changeClass: {
            type: Function,
            default: () => {
            }
        },
        categoryName: {
            type: String,
            default: ""
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 2000);
    },
    methods: {},
}
</script>

<style scoped>

.class-item:last-child{
    margin-right:0 !important;
}

.class-item {
    width: 7rem;
    display: inline-block;
    height: 100%;
    margin-right: 1.25rem;
    border-radius: 1rem;
    position: relative;
    overflow:visible;
}

.class-item-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 1rem;
    width: 7rem;
    height: 100%;
}

.class-item-box:last-child {
    margin-right: 0;
}

.class-item-box.active {
    background: var(--gdient2);
}

.class-item-box.active span {
    color:#fff;
}

.class-item span {
    color:var(--subfont);
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight:bold;
}

.class-item img {
    height: 3rem;
}
</style>
