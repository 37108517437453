<template>
    <div class="drawerShadow" @click="closeModal" :style=" visible ? '' : 'display:none' ">
        <div @click.stop="defaultF" class="drawerBox" :style="getStyle()">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Drawer",
        props:{
            visible:{
                type:Boolean,
                default:false,
            },
        },
        data(){
            return{
                width:0
            }
        },
        mounted(){
            const resize = () => {
                this.$nextTick(() => {
                    if(document.body.style.maxWidth.trim()){
                        this.width = parseInt(document.body.style.maxWidth);
                    }else{
                        this.width = window.innerWidth;
                    }
                })
            }
            resize();

            window.addEventListener('resize', resize);
        },
        methods:{
            defaultF(){

            },
            closeModal(){
                this.$emit("update:visible",false)
            },
            getStyle(){
                let result = "";

                // if(this.gradient){
                //     result += 'background: linear-gradient(to bottom, #0b2b62, var(--sub8black));'
                // }else if (!this.hasBg){
                //     result += 'background:none;';
                // }
                // if(!this.padding){
                //     result += 'padding:0 !important;'
                // }

                result += "max-width:" + this.width + 'px;'

                return result;
            }
        }
    }
</script>

<style scoped>
    .drawerBox{
        width:100%;
        background:var(--primary);
        position:absolute;
        bottom:0;
        height:fit-content;
        box-sizing:border-box;
        padding:2rem;
        z-index:9999999999999;
    }
    .drawerShadow{
        height:100%;
        width:100%;
        position:fixed;
        z-index:9999;
        background-color:rgba(0,0,0,.8);
        display:flex;
        justify-content:center;
        align-items: center;
        top:0;
        left:0;
        bottom:0;
    }
</style>
